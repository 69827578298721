import React, { useCallback, useMemo, useState } from "react"
import { graphql } from "gatsby"
import { GatsbyImage as Img, getImage, withArtDirection } from 'gatsby-plugin-image'
// import Slider from "react-slick"
import { FormattedMessage } from "react-intl"

import Layout from "../components/layout"
// import { Link } from "../components/link"
import TileList from "../components/tile-list"
import VideoModal from "../components/video-modal"
import Attachments from "../components/attachments"
import PageFascia from "../components/page-fascia"
import Seo from "../components/seo"
import SocialShare from "../components/elements/social-share"
import withPreview from "../utility/with-preview"
import pageUrl from "../utility/page-url"

import PlayArrowIcon from '../images/icons/material/play_arrow.svg'
// import ChevronRightIcon from '../images/icons/material/chevron_right.svg'

// import SportIcon from "../images/icons/sport.svg"


// const settings = {
//   arrows: false,
//   dots: false,
//   infinite: false,
//   autoplay: false,
//   slidesToShow: 5,
//   adaptiveHeight: true,
//   responsive: [
//     {
//       breakpoint: 992,
//       settings: {
//         slidesToShow: 4,
//         slidesToScroll: 1
//       }
//     },
//     {
//       breakpoint: 576,
//       settings: {
//         slidesToShow: 2.4,
//         slidesToScroll: 2
//       }
//     }
//   ]
// };

const ShortFormTemplate = ({ data: { meta, page, /*menuCorporate*/ }, pageContext: { langCode } }) => {
  const { htmlTitle, pageTitle, mainTitle, mainSubtitle, heroImage, mainText, attachmentTitle, attachment,
    closeImage, relatedTiles, fasce } = page;

  const sources = useMemo(() => {
    if (!heroImage || !heroImage.node) return null;
    return heroImage.content?.heroMobile?.node || heroImage.content?.heroTablet?.node ? withArtDirection(getImage(heroImage.node), []
    .concat(
      heroImage.content?.heroTablet?.node ? {
        media: "(max-width: 992px)",
        image: getImage(heroImage.content.heroTablet.node),
      }: []
    ).concat(
      heroImage.content?.heroMobile?.node ? {
        media: "(max-width: 576px)",
        image: getImage(heroImage.content.heroMobile.node),
      }: []
    )): getImage(heroImage.node);
  }, [heroImage]);

  const videoUrl = heroImage && heroImage.content && heroImage.content.videoLink && heroImage.content.videoLink.value;
  const [videoVisible, setVideoVisible] = useState(false);
  const showVideo = useCallback(() => {
    if (videoUrl) {
      setVideoVisible(true);
    }
  }, [videoUrl]);
  const closeVideo = useCallback(() => setVideoVisible(false), []);

  const canonical = (htmlTitle.content && htmlTitle.content.canonicalUrl && htmlTitle.content.canonicalUrl.value) 
    || `${meta.siteUrl}/${pageUrl(page)}`;

  return (
    <Layout langCode={langCode} tags={page.tags}>
      <Seo title={htmlTitle.value || pageTitle.value} langCode={langCode} ogImage={htmlTitle.content.ogImage}
        description={htmlTitle.content && htmlTitle.content.metaDescription && htmlTitle.content.metaDescription.value}
        skipEn={pageTitle.content && pageTitle.content.skipEn} canonical={canonical}
        robots={htmlTitle.content && htmlTitle.content.metaRobots && htmlTitle.content.metaRobots.value} />
      <div className={`news-detail news-detail--short${sources ? ' news-detail--testata' : ''}`}>
        <div className="news-detail__intro-short text-center">
          <h1 className="news-detail__title text-gradient-primary mb-1" 
            dangerouslySetInnerHTML={{__html: (mainTitle && mainTitle.value) || (pageTitle && pageTitle.value) }}></h1>
          {mainSubtitle && mainSubtitle.value ? <div dangerouslySetInnerHTML={{__html: mainSubtitle.value }}></div>: null}
          <SocialShare className="d-none d-md-block" page={page} title={page.pageTitle.value} siteUrl={meta.siteUrl} />
        </div>
        {sources ? (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/no-noninteractive-tabindex
          <div className={`news-detail__hero${videoUrl ? ' news-detail__hero--video': ''}`} tabIndex="0" 
            onClick={showVideo}>
            <Img image={sources} alt={(heroImage && heroImage.node && heroImage.node.description) || ''} 
              className="news-detail__hero-img" />
            {videoUrl ? (
              <div className="news-detail__hero-play" >
                <PlayArrowIcon className="material-svg text-white" />
              </div>
            ) : null}
          </div>
        ): null}
        <div className="section pb-5 mb-5">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <SocialShare className="d-md-none mt-3" page={page} title={page.pageTitle.value} siteUrl={meta.siteUrl} />
                <div className="section__content pb-5 pt-1 pt-md-5" dangerouslySetInnerHTML={{__html: mainText.value }}></div>
              </div>
            </div>
            {closeImage && closeImage.node ? (
              <div className="row">
                <div className="col-12">
                  <Img image={getImage(closeImage.node)} alt={closeImage.node.description || ''} />
                </div>
              </div>
            ): null}
          </div>
        </div>
      </div>
      {fasce ? fasce.map((fascia, i) => <PageFascia key={i} data={fascia} />) : null}
      {attachment && attachment.length ? <Attachments data={{ attachmentTitle, attachment }} /> : null}
      {relatedTiles && relatedTiles.length ? (
        <div className="section pb-5 mb-5">
          <div className="container">
            <div className="row mb-3">
              <div className="col-12">
                <div className="news-detail__banner-title font-weight-bold">
                  <FormattedMessage id="related-media-intro" />
                </div>
              </div>
            </div>
          </div>
          <TileList tiles={relatedTiles} rows={1} related={true} />
        </div>
      ): null}
      {/* <div className="section mt-5">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h3 className="mb-5">Engie per te</h3>
              <p className="text-blue-corporate font-weight-bold mb-5"></p> 
            </div>
          </div>
        </div>
        <div className="news-detail__menu mx-auto mb-5 pb-5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 pr-0 pr-lg-2">
                <Slider className="hero__menu-carousel" {...settings} >
                  {menuCorporate.pageTitle.map((item, i) => (
                    <div key={i}>
                      <div className={`hero__menuitem${i === 0 ? ` hero__menuitem--first` : ''}`}>
                        {item.content.icon.value === 'sport' ? <SportIcon className="hero__icon mb-1" /> : <i className={`nj-icon nj-icon-${item.content.icon.value} mb-1`}></i>}
                        <h4 className="hero__menutitle">
                          <Link to={item.content.link.value}>
                            {item.value}
                          </Link>
                        </h4>
                        <div className="hero__menutext d-none d-lg-block">{item.content.description.value}</div>
                        {item.content.ctaText && (
                          <div className="hero__menulink">
                            {item.content.ctaText.map((el, i) => (
                              <div key={i}>
                                <a href={el.content.ctaLink.value} target="_blank" rel="noopener noreferrer">
                                  {el.value}
                                  <ChevronRightIcon className="material-svg" />
                                </a>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {videoUrl ? <VideoModal show={videoVisible} handleClose={closeVideo} video={videoUrl} /> : null}
    </Layout>
  );
}

export default withPreview(ShortFormTemplate, { subField: 'page', fixed: true });
export const query = graphql`query ShortFormQuery($liferayId: Int!, $langCode: String!){
  meta: gatsbySourceLiferayOptions {
    siteUrl
  }
  page: liferayShortForm(liferayId: { eq: $liferayId }, langCode: { eq: $langCode}) {
    siteId
    articleId
    langCode
    pageTitle {
      value
      content {
        friendlyUrl {
          value
        }
        skipEn
      }
    }
    htmlTitle {
      value
      ...ShortFormTitleFragment
    }
    mainTitle {
      value
    }
    mainSubtitle {
      value
    }
    mainText {
      value
    }
    heroImage {
      node {
        description
        gatsbyImageData(width: 1440)
      }
      content {
        videoLink {
          value
        }
        heroTablet {
          node {
            gatsbyImageData(width: 992)
          }
        }
        heroMobile {
          node {
            gatsbyImageData(width: 576)
          }
        }
      }
    }
    closeImage {
      node {
        description
        gatsbyImageData(width: 1108)
      }
    }
    ...FasceShortFragment
    attachmentTitle {
      value
    }
    attachment {
      node {
        publicURL
      }
      content {
        attachmentDescription {
          value
        }
      }
    }
    relatedTiles {
      ...TileFragment
    }
    tags: taxonomyCategoryBriefs {
      taxonomyCategoryId
      taxonomyCategoryName
    }
  }
  menuCorporate: liferayMenuCorporate(langCode: {eq: $langCode}){
    pageTitle {
      value
      content {
        icon {
          value
        }
        description {
          value
        }
        link {
          value
        }
        ctaText {
          value
          content {
            ctaLink {
              value
            }
          }
        }
      }
    }
  }   
}`


